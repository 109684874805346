body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f4f4f4;
}


.management-form-inner {
	display: grid;
	grid-template-columns: auto auto;
	column-gap: 32px;
	row-gap: 4px;
	margin-top: 8px;
}